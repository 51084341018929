import { format } from 'date-fns';

export const convertToDate = (date: string) => {
  return new Date(date);
};

export const convertDateToString = (date: Date) => {
  return date.toISOString();
};

export const convertDatetoDateFormatted = (date: Date) =>
  format(new Date(date), 'dd/MM/yyyy');

export const convertDatetoDatetimeFormatted = (date: Date) =>
  format(new Date(date), 'dd/MM/yyyy HH:mm');

export const convertToDateISOString = (date: string) => {
  try {
    let year, month, day;
    if (date.includes('-')) {
      year = +date.substring(0,4); 
      month = +date.substring(5,7);
      day = +date.substring(8,10);
    } else if (date.includes('/')) {
      day = +date.substring(0,2);
      month = +date.substring(3,5);
      year = +date.substring(6,10); 
    } else {
      year = +date.substring(0,4); 
      month = +date.substring(4,6);
      day = +date.substring(6,8);
    }
    return new Date(year, month-1, day).toISOString();
  } catch(e){
    return null;
  }
}