import React, { useEffect, useState } from 'react';
import { IFileDto } from '../../../dto/Reports';
import { getDocumentsApi, getMyDocumentsApi } from '../../../api/reports';
import { ErrorDisplays, PagePrincipal } from '../../../containers';
import { useTranslation } from 'react-i18next';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.css';
import TreeView from '@material-ui/lab/TreeView';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeItem from '@material-ui/lab/TreeItem';
import FolderIcon from '@material-ui/icons/Folder';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Filtre from './filtres';
import { MyDocumentsDto, typeDocument, Filtres } from './types';
import { first, uniq } from 'lodash';
import { getFileAttachment, getFileDocument } from '../../../api/file';
import { IErrorApiBase } from '../../../api/_base/types';
import { Loading } from '../../../components';
import { PQRS_NODE, PROCESS_NODE, OTHER_NODE } from '../../../constants/DocumentTypeNodes';
import { KeyCodes } from '../../../constants/KeyCode';
import { EcmModule } from '../../../constants/EcmModule';

const PorfolioMyDocuments = () => {
  const { t } = useTranslation();
  const [documents, setDocuments] = useState<MyDocumentsDto[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [documentsFiltred, setDocumentsFiltred] = useState<MyDocumentsDto[]>(
    []
  );
  const [errorValidation, setErrorValidation] = useState<
    IErrorApiBase[] | undefined
  >();
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const [pqrs, other, process] = await getMyDocumentsApi();
      const _pqrs = pqrs.map((p) => ({ ...p, type: typeDocument.pqrs }));
      const _other = other.map((p) => ({ ...p, type: typeDocument.other }));
      const _process = process.map((p) => ({
        ...p,
        type: typeDocument.process,
      }));
      setDocuments([..._pqrs, ..._other, ..._process]);
      setDocumentsFiltred([..._pqrs, ..._other, ..._process]);
      setIsLoading(false);
    };
    fetch();
  }, []);

  const donwloadDocument = async (
    info: IFileDto,
    isAttachment: boolean,
    ecmModule: EcmModule
  ) => {
    const [success, errors] = isAttachment
      ? await getFileAttachment(info.docId, info.attachmentId, info.description, ecmModule)
      : await getFileDocument(info.docId, info.description);
    if (!success) setErrorValidation(errors);
  };
  const labelFolder = (text: string) => (
    <div className='flex space-x-4'>
      <FolderIcon fontSize='large' htmlColor='orange' />
      <span className='text-xl mt-1'>{text}</span>
    </div>
  );

  const labelPdf = (info: IFileDto, isAttachment: boolean, nodeId: string) => {
    const ecmModule = nodeId === PROCESS_NODE ? EcmModule.Process : EcmModule.Document;
    return(
      <div
        className='flex space-x-4'
        onClick={() => donwloadDocument(info, isAttachment, ecmModule)}
      >
        <PictureAsPdfIcon htmlColor='red' />
        <span className='text-lg mt-1'>{info.description}</span>
      </div>
    );
  }

  const onKeyDown = (info: IFileDto, isAttachment: boolean, nodeId: string) => (e: any) => {
    const ecmModule = nodeId === PROCESS_NODE ? EcmModule.Process : EcmModule.Document;
    if (e.keyCode === KeyCodes.Enter) donwloadDocument(info, isAttachment, ecmModule);
  }

  const hasFiltres = (filtres: Filtres) =>
    filtres.dateFinal ||
    filtres.dateInitial ||
    filtres.documentNumber ||
    filtres.withAnswer ||
    filtres.withAttachment ||
    filtres.year;

  const onFiltreDocument = (filtres: Filtres) => {
    if (!hasFiltres(filtres)) {
      setDocumentsFiltred(documents);
      return;
    }
    const documentsClone = [...documents];
    let documentsCloneFiltred: MyDocumentsDto[] = [...documentsClone];

    if (filtres.withAnswer) {
      documentsCloneFiltred = documentsClone.filter(p => p.hasAnswers);
    }
    if (filtres.withAttachment) {
      documentsCloneFiltred = [...documentsCloneFiltred.filter(p => p.hasAttachments)];
    }

    if (filtres.documentNumber) {
      documentsCloneFiltred = [
        ...documentsCloneFiltred.filter((p) =>
          p.documentNumber.includes(filtres.documentNumber!)
        ),
      ];
    }

    if (filtres.year) {
      documentsCloneFiltred = [
        ...documentsCloneFiltred.filter(
          (p) =>
            p.creationDate &&
            new Date(p.creationDate).getFullYear() === filtres.year
        ),
      ];
    }

    if (filtres.dateInitial && filtres.dateFinal) {
      filtres.dateInitial.setHours(0, 0, 0, 0);
      filtres.dateFinal.setHours(23, 59, 59, 999);
      documentsCloneFiltred = [
        ...documentsCloneFiltred.filter(
          (p) =>
            p.creationDate &&
            new Date(p.creationDate) >= filtres.dateInitial! &&
            new Date(p.creationDate) <= filtres.dateFinal!
        ),
      ];
    }

    setDocumentsFiltred(uniq(documentsCloneFiltred));
  };

  const handleNodeToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    let type: typeDocument;
    if (nodeIds.includes(PQRS_NODE)){
      type = typeDocument.pqrs;
    } else if (nodeIds.includes(PROCESS_NODE)){
      type = typeDocument.process;
    } else if (nodeIds.includes(OTHER_NODE)){
      type = typeDocument.other;
    }
    nodeIds = nodeIds.filter(n => !n.includes('_node') && !expandedNodes.includes(n));
    const nodeId = first(nodeIds);
    if (nodeId){
      setIsLoading(true);
      const fetch = async () => {
        const result = await getDocumentsApi(nodeId, type);
        if (!result) return;
        let documentsCloneFiltred = [...documentsFiltred];
        documentsCloneFiltred = documentsCloneFiltred.map(doc => {
          if (doc.docId === nodeId || doc.label === nodeId){
            doc.answers = result.answers;
            doc.attachments = result.attachments;
          }
          return doc;
        });
        setDocumentsFiltred(documentsCloneFiltred);
        setExpandedNodes([...expandedNodes, nodeId]);
        setIsLoading(false);
      };
      fetch();
    }
  }

  const renderTree = (
    node: MyDocumentsDto[],
    title: string,
    nodeId: string
  ) => (
    <TreeItem
      nodeId={nodeId}
      label={labelFolder(title)}
      key={`idNode_${nodeId}`}
      tabIndex={0}
    >
      {node.map((p) => (
        <TreeItem
          nodeId={p.type === typeDocument.process ? p.label : p.docId}
          label={labelFolder(p.label)}
          key={`TreeItem_${nodeId}_${p.documentNumber || p.label}`}
        >
          {p.documentNumber ? (
            <TreeItem
              nodeId={`${nodeId}${p.documentNumber}original`}
              label={labelPdf(
                {
                  docId: p.docId,
                  description: `${p.documentNumber}.pdf`,
                  attachmentId: '',
                },
                false,
                nodeId
              )}
              key={`${nodeId}${p.documentNumber}original`}
              onKeyDown={onKeyDown({ docId: p.docId, description: `${p.documentNumber}.pdf`, attachmentId: '' }, false, nodeId )}
            ></TreeItem>
          ) : (
            t('pages.porfolioMyDocuments.withoutDocumentInCreation')
          )}

          {p.attachments.length > 0 && (
            <TreeItem
              nodeId={`${nodeId}${p.documentNumber}Attachements`}
              label={labelFolder(t('pages.porfolioMyDocuments.attachement'))}
              key={`${nodeId}${p.documentNumber}Attachements`}
            >
              {p.attachments.map((x) => (
                <TreeItem
                  nodeId={`${nodeId}${p.documentNumber}${x.docId}${x.description}Answers`}
                  key={`${nodeId}${p.documentNumber}${x.docId}${x.description}Answers`}
                  label={labelPdf(x, true, nodeId)}
                  onKeyDown={onKeyDown(x, true, nodeId)}
                ></TreeItem>
              ))}
            </TreeItem>
          )}
          {p.answers.length > 0 && (
            <TreeItem
              nodeId={`${nodeId}${p.documentNumber}Answers`}
              label={labelFolder(t('pages.porfolioMyDocuments.answer'))}
              key={`${nodeId}${p.documentNumber}Answers`}
            >
              {p.answers.map((x) => (
                <TreeItem
                  nodeId={`${nodeId}${p.documentNumber}${x.docId}Respuesta`}
                  label={labelPdf(x, false, nodeId)}
                  onKeyDown={onKeyDown(x, false, nodeId)}
                  key={`${nodeId}${p.documentNumber}${x.docId}Respuesta`}
                ></TreeItem>
              ))}
            </TreeItem>
          )}
        </TreeItem>
      ))}
    </TreeItem>
  );
  return (
    <PagePrincipal title={t('pages.porfolioMyDocuments.title')}>
      <div className='flex flex-col space-y-5 md:flex-row p-0 xl:pl-12  xl:pr-40 space-x-5 lg:space-y-0'>
        <div className='w-full h-full lg:w-1/3'>
          <Filtre onFiltre={onFiltreDocument} />
        </div>
        <div className='w-full h-full'>
          <div className='w-full flex justify-center lg:-ml-20'>
            <TreeView
              className='w-auto'
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={<div style={{ width: 50 }} />}
              onNodeToggle={handleNodeToggle}
            >
              {renderTree(
                documentsFiltred.filter((p) => p.type === typeDocument.pqrs),
                t('pages.porfolioMyDocuments.pqrs'),
                PQRS_NODE
              )}
              {renderTree(
                documentsFiltred.filter((p) => p.type === typeDocument.process),
                t('pages.porfolioMyDocuments.process'),
                PROCESS_NODE
              )}
              {renderTree(
                documentsFiltred.filter((p) => p.type === typeDocument.other),
                t('pages.porfolioMyDocuments.other'),
                OTHER_NODE
              )}
            </TreeView>
          </div>
        </div>
      </div>
      <ErrorDisplays errors={errorValidation} module='downloadDoc' />
      <Loading loading={isLoading} />
    </PagePrincipal>
  );
};
export default PorfolioMyDocuments;
