import React, { useEffect, useState } from 'react';
import { ButtonCaptcha, Loading } from '../../components';
import { BasicModal } from '../../components';
import { useTranslation } from 'react-i18next';
import { EnumTypePQRS } from '../../constants/PqrsType';
import { DocumentMetaData, PagePrincipal, PolicyData } from '../../containers';
import { DocumentMetaDataState } from '../../containers/DocumentMetaData/types';
import { IValidatorField } from '../../hooks/validation/useValidator/types';
import { mapStateToDto, mapStateToDtoFiles } from './mapper';
import { createPqrs, addFilesPqrs, getDocumentType } from '../../api/pqrs';
import { CreatePqrsResponse } from '../../api/pqrs/types';
import { useHistory } from 'react-router';
import RouteNavigation from '../../constants/RouteNavigation';
import { MessageTerms } from '../../components/MessageTerms';

interface ContainerCommonProps {
  typePqrs: EnumTypePQRS;
  title?: string;
  subTitle?: string;
}

const Pqrsd = ({ title, subTitle, typePqrs }: ContainerCommonProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [validateData, setValidateData] = useState<number>(0);
  const [openDialogPolicy, setOpenDialogPolicy] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [IsEnd, setIsEnd] = useState<boolean>(false);
  const [documentTypeId, SetDocumentTypeId] = useState<number>();

  const [pqrsResponse, setPqrsResponse] = useState<
    CreatePqrsResponse | undefined
  >();
  const [metaData, setMetaData] = useState<DocumentMetaDataState | undefined>();

  
  useEffect(() => {
    const fetch = async () => {
      const result = await getDocumentType(typePqrs);
      if(result) {
        SetDocumentTypeId(result);
        setIsEnd(true);
      }
    };
    fetch();
  }, [typePqrs]);
  
  const onAcceptTerms = async (accepted: boolean) => {
    setOpenDialogPolicy(false);
    if (metaData) {
      setIsLoading(true);
      const data = mapStateToDto(typePqrs, metaData, accepted);
      const files = mapStateToDtoFiles(metaData);
      const result = await createPqrs(data);
      if (result) {
        await addFilesPqrs(result.docId, files);
        setPqrsResponse(result);
        setAlertOpen(true);
        setIsEnd(true);
      }
      setIsLoading(false);
    }
  };

  const onSave = () => {
    setValidateData(validateData + 1);
  };

  const onValidationMetaData = async (
    hasError: boolean,
    data: DocumentMetaDataState,
    validations: IValidatorField[]
  ) => {
    if (hasError) return;
    setOpenDialogPolicy(true);
    setMetaData(data);
  };

  const redirectPqrs = () => {
    history.push(`/${RouteNavigation.pqrsInformation}/${typePqrs}`);
  };

  return (
    <PagePrincipal title={title} subTitle={subTitle} withContainer>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-2 pb-16'>
        {documentTypeId && (
          <DocumentMetaData
            documentTypeId={documentTypeId}
            validateData={validateData}
            onValidationMetaData={onValidationMetaData}
          />
        )}
      </div>

      <div className='flex justify-center mb-8'>
        <div className='w-96'>
          {IsEnd && (
            <div>
              <ButtonCaptcha
                fullWidth
                textButton={t('pages.pqrs.common.save')}
                onClick={onSave}
              />
            </div>
          )}
        </div>
      </div>

      <MessageTerms typePqrs={typePqrs} />

      <PolicyData
        typePqrs={typePqrs}
        onChange={onAcceptTerms}
        open={openDialogPolicy}
        onClose={() => setOpenDialogPolicy(false)}
      />
      <Loading loading={isLoading} />

      <BasicModal
        open={alertOpen}
        title={t('pages.pqrs.common.modalSuccess.title')}
        showAcceptButton
        titleButtonAccept={t('pages.pqrs.common.modalSuccess.btnAccept')}
        onClickAccept={redirectPqrs}
      >
        <div>
          <div className='text-xl'>
            {t('pages.pqrs.common.modalSuccess.messageSuccess1')}{' '}
            <span className='text-2xl font-bold text-black'>
              {pqrsResponse?.documentNumber}
            </span>
          </div>
          <div className='text-xl'>
            {t('pages.pqrs.common.modalSuccess.messageSuccess2')}{' '}
            <span className='text-2xl font-bold text-black'>
              {pqrsResponse?.accessCode}
            </span>
          </div>
        </div>
      </BasicModal>
    </PagePrincipal>
  );
};
export default Pqrsd;
