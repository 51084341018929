import { EcmModule } from '../../constants/EcmModule';
import { API_ECM } from '../constants';
import { getFile } from '../_base';
import { IErrorApiBase } from '../_base/types';
import { FileRequest, FileRequestAccessCode, FileRequestModule, FileRequestModuleAccessCode } from './types';

const base = `${API_ECM}/v2/DownLoadDocument`;

const url = {
  getFileOriginal: `${base}/Document`,
  getFileAnswer: `${base}/Attachment/Module`,
  getFileOriginalByAccessCode: `${base}/DocumentByAccessCode`,
  getFileAnswerByAccessCode: `${base}/AttachmentByAccessCode`,
};

export const getFileDocument = async (
  docId: string,
  fileName: string
): Promise<[boolean, IErrorApiBase[] | undefined]> => {
  const _url = `${url.getFileOriginal}`;
  const body: FileRequest = {
    docId,
  };
  const result = await getFile(_url, body, fileName);
  if (result && !result.hasError && result.data) return [true, undefined];
  else return [false, result.error?.errors ];
};

export const getFileAttachment = async (
  docId: string,
  attachmentId: string,
  fileName: string,
  ecmModule: EcmModule = EcmModule.Document
): Promise<[boolean, IErrorApiBase[] | undefined]> => {
  const _url = `${url.getFileAnswer}`;
  const body: FileRequestModule = {
    id: docId,
    attachmentId,
    ecmModule
  };
  const result = await getFile(_url, body, fileName);
  if (result && !result.hasError && result.data) return [true, undefined];
  else return [false, result.error?.errors ];
};

export const getFileDocumentByAccessCode = async (
  request: FileRequestAccessCode,
  fileName: string
): Promise<[boolean, IErrorApiBase[] | undefined]> => {
  const _url = `${url.getFileOriginalByAccessCode}`;
  const body: FileRequestAccessCode = { ...request };
  const result = await getFile(_url, body, fileName);
  if (result && !result.hasError && result.data) return [true, undefined];
  else return [false, result.error?.errors ];
};

export const getFileAttachmentByAccessCode = async (
  request : FileRequestModuleAccessCode,
  fileName: string,
): Promise<[boolean, IErrorApiBase[] | undefined]> => {
  const _url = `${url.getFileAnswerByAccessCode}`;
  const body: FileRequestModuleAccessCode = { ...request, ecmModule: EcmModule.Document };
  const result = await getFile(_url, body, fileName);
  if (result && !result.hasError && result.data) return [true, undefined];
  else return [false, result.error?.errors ];
};

