import {
  CountryDto,
  DepartmentDto,
  CityDto,
  TreatmentDto,
  PersonTypeDto,
  IdentificationTypeDto,
  LanguageDto,
  UserTypeDto,
  InformationCustomTableDto,
  DocumentTypeDto,
} from '../../dto/admin/types';
import { getCurrentLanguageDto } from '../../utilitys/language';
import { API_ECM } from '../constants';
import { GetUserTableInformationApiRequest } from '../pqrs/types';
import { getData, postData } from '../_base';

const base = `${API_ECM}/v2/AdmInquiries`;

const url = {
  getContries: `${base}/Country`,
  getDepartments: `${base}/Department`,
  getCities: `${base}/City/ByDepartment`,
  getIdentificationType: `${base}/identificationType/ByPersonType`,
  getPersonType: `${base}/PersonType`,
  getTreatment: `${base}/Treatment`,
  getLanguage: `${base}/Language`,
  getHoliDays: `${base}/HoliDays`,
  getUserType: `${base}/UserType`,
  getTableInformation: `${base}/TableInformation`,
  getDocumentType: `${base}/DocumentTypes`,
};


export const getLanguageApi = async (): Promise<LanguageDto[]> => {
  const _url = `${url.getLanguage}`;
  const result = await getData<LanguageDto[]>(_url);
  if (result && !result.hasError && result.data ) return result.data as LanguageDto[];
  return [];
};

export const getCountriesApi = async (): Promise<CountryDto[]> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getContries}/${lngDto.id}`;
  const result = await getData<CountryDto[]>(_url);
  if (result && !result.hasError && result.data ) return result.data as CountryDto[];
  return [];
};

export const getDeparmentsApi = async (
  countryId: string
): Promise<DepartmentDto[]> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getDepartments}/${lngDto.id}/${countryId}`;
  const result = await getData<DepartmentDto[]>(_url);
   if (result && !result.hasError && result.data ) return result.data as DepartmentDto[];
  return [];
};

export const getCitiesApi = async (
  departmentId: string
): Promise<CityDto[]> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getCities}/${lngDto.id}/${departmentId}`;
  const result = await getData<CityDto[]>(_url);
   if (result && !result.hasError && result.data ) return result.data as CityDto[];
  return [];
};

export const getPersonTypeApi = async (): Promise<PersonTypeDto[]> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getPersonType}/${lngDto.id}`;
  const result = await getData<PersonTypeDto[]>(_url);
   if (result && !result.hasError && result.data ) return result.data as PersonTypeDto[];
  return [];
};

export const getIdentificationTypeApi = async (
  personTypeId: number
): Promise<IdentificationTypeDto[]> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getIdentificationType}/${lngDto.id}/${personTypeId}`;
  const result = await getData<IdentificationTypeDto[]>(_url);
   if (result && !result.hasError && result.data ) return result.data as IdentificationTypeDto[];
  return [];
};

export const getTreatmentApi = async (): Promise<TreatmentDto[]> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getTreatment}/${lngDto.id}`;
  const result = await getData<TreatmentDto[]>(_url);
   if (result && !result.hasError && result.data ) return result.data as TreatmentDto[];
  return [];
};

export const getHoliDaysApi = async (): Promise<Date[]> => {
  const _url = `${url.getHoliDays}`;
  const result = await getData<Date[]>(_url);
   if (result && !result.hasError && result.data ) return result.data as Date[];
  return [];
};

export const getUserTypeApi = async (): Promise<UserTypeDto[]> => {
  const lngDto = getCurrentLanguageDto();
  const _url = `${url.getUserType}/${lngDto.id}`;
  const result = await getData<UserTypeDto[]>(_url);
   if (result && !result.hasError && result.data ) return result.data as UserTypeDto[];
  return [];
};


export const getUserTableInformationApi = async (
  tableName: string,
  value: string,
  text: string,
  documentType?: number,
  dicSec?: number,
  fieldDepedent: string | undefined = undefined,
  valueDependent: string | undefined = undefined
): Promise<InformationCustomTableDto[]> => {
  let _url = `${url.getTableInformation}`;
  const lngDto = getCurrentLanguageDto();

  const data = {
    tableName,
    value,
    text,
    documentType,
    dicSec,
    fieldDepedent,
    valueDependent,
    language: lngDto.id
  };

  const result = await postData<
    GetUserTableInformationApiRequest,
    InformationCustomTableDto[]
  >(_url, data);
  if (result && !result.hasError && result.data) return result.data;
  else return [];
};


export const getDocumetTypeIdByProcessGuidIdApi = async (processGuidId: string): Promise<DocumentTypeDto | undefined> => {
  const _url = `${url.getDocumentType}/${processGuidId}`;
  const result = await getData<DocumentTypeDto[]>(_url);
  if (result && !result.hasError && result.data && result.data[0]) return result.data[0] as DocumentTypeDto;
  return undefined;
};
