import React, { memo, forwardRef, useImperativeHandle, useState } from 'react';
import { AudioRecorder, TextField } from '../../components';
import { MetaDataConfigurationDocumentaryTypeDto } from '../../dto/MetadataDto';
import {
  HandleDocumentMetaDataChild,
  ResultDocumentMetaDataChild,
  SpeechAudioType,
  TypeControl,
} from './types';
import {
  LinearProgress,
} from '@material-ui/core';
import { transcribeSpeech } from '../../api/ia';
import { useTranslation } from 'react-i18next';

interface FileUploadMemoProps {
  value?: Blob | string;
  ref: any;
  error?: string;
  documentTypeId?: number;
  metaData: MetaDataConfigurationDocumentaryTypeDto;
}

const AudioRecorderMemo = memo(
  forwardRef(
    (
      props: FileUploadMemoProps,
      ref: React.Ref<HandleDocumentMetaDataChild | undefined>
    ) => {
      const {
        error,
        metaData: {
          isRequired,
          id,
          toolTip,
          label,
          initial,
          dicSec,
          attributeFormId,
        },
      } = props;

      const { t } = useTranslation();
      const [file, setFile] = useState<Blob | undefined>();
      const [isLoading, setIsLoading] = useState<boolean>(false)
      const [speech, setSpeech] = useState<string | undefined>();

      useImperativeHandle(ref, () => ({
        getData() {
          return {
            id,
            value: file && speech
              ? ({
                  audio: file,
                  speech: speech,
                } as SpeechAudioType)
              : undefined,
            text: '',
            typeElement: TypeControl.audio,
            label: label,
            initial: initial,
            dicSec: dicSec,
            attributeFormId,
          } as ResultDocumentMetaDataChild;
        },
      }));

      const handleOnLoadFile = async (file: Blob | undefined) => {
        setFile(file);
        if (file) {
          setIsLoading(true)
          const result = await transcribeSpeech(file, `${label}_.wav`);
          setSpeech(result?.speech ?? t('message.validation.transcribeSpeeError'));
          setIsLoading(false)
        }else{
          setSpeech(undefined);
        }
      };

      const onChangeSpeech = (value: string) => {
        setSpeech(value);
      };

      return (
        <div className='space-x-2'>
          <AudioRecorder
            label={label}
            isRequired={isRequired}
            textHelp={toolTip}
            error={error}
            onLoadFile={handleOnLoadFile}
          />
          <br />
          {isLoading && <LinearProgress className='w-24' />}
          <br />
          {speech && (
            <TextField
              label={t('components.audioRecorder.resultText')}
              disabled={false}
              isOutlined
              isRequired={isRequired}
              value={speech}
              error={error}
              onChange={onChangeSpeech}
              multiline
              rows={10}
            />
          )}
        </div>
      );
    }
  )
);
export default AudioRecorderMemo;
