import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INavigationItem } from '../../../navigation/types';
import navigationConfig from '../../../navigation';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { useHistory, useLocation } from 'react-router-dom';
import RouteNavigation from '../../../constants/RouteNavigation';
import { hasUserAccessMenu } from '../../../utilitys/security';
import { userContext } from '../../../hooks/contexts/userContext';
import { KeyCodes } from '../../../constants/KeyCode';
import contextApp from '../../../hooks/contexts/contextApp';

const MenuOptions = () => {
  const { t } = useTranslation();
  const [openCollapses, setOpenCollapses] = useState<string[]>([]);
  const history = useHistory();
  const location = useLocation();
  const { userAuth } = useContext(userContext);
  const ctxApp = useContext(contextApp);

  const onClickItem = (
    id: string,
    hasChild: boolean,
    url?: string
  ) => {
    if (hasChild) {
      toogleCollapse(id);
    }
    if (url === RouteNavigation.Home) {
      history.push(`${url}`);
    } else if (url) {
      history.push(`/${url}`);
    }
  }

  const onKeyDownItem = (id: string, hasChild: boolean, url?: string) => (e: any) => {
    if (e.keyCode === KeyCodes.Enter) onClickItem(id, hasChild, url);
  }

  const isCollapseOpen = (id: string) => openCollapses.includes(id);

  const toogleCollapse = (id: string) => {
    if (isCollapseOpen(id)) {
      const collapsWithout = openCollapses.filter((p) => p !== id);
      setOpenCollapses(collapsWithout);
    } else {
      const collapsWith = [...openCollapses, id];
      setOpenCollapses(collapsWith);
    }
  };

  const builItem = (
    id: string,
    keyTranslate: string,
    icon: string,
    hasChilds: boolean,
    url: string | undefined
  ) => (
    <ListItem button key={id} selected={`/${url}` === location.pathname} onKeyDown={onKeyDownItem(id, hasChilds, url)}>
      <ListItemIcon onClick={() => onClickItem(id, hasChilds, url)}>
        <Icon>{icon}</Icon>
      </ListItemIcon>
      <ListItemText
        primary={getTextTranslate(keyTranslate)}
        onClick={() => onClickItem(id, hasChilds, url)}
      />
      {hasChilds && renderIconsExpand(id)}
    </ListItem>
  );

  const renderIconsExpand = (id: string) => {
    if (isCollapseOpen(id)) return <ExpandLess />;
    else return <ExpandMore />;
  };

  const hasUserAccess = (menu: INavigationItem) => {
    const _hasAccess = hasUserAccessMenu(menu, userAuth);
    if(userAuth && menu.hideOnAuthenticated) return false;
    return _hasAccess;
  };

  const getTextTranslate = (keyTranslate: string) =>
      ctxApp.menuOptions?.find(x => x.code === keyTranslate)?.value || t(`navigation.${keyTranslate}`);

  const buildMenu = () => {
    return navigationConfig
      .filter((p) => !p.hide && hasUserAccess(p))
      .map((item) => {
      if (item.children) {
        return (
          <Fragment key={`menuOption${item.id}`}>
            {builItem(
              item.id,
              item.keyTranslate,
              item.icon || '',
              true,
              item.url
            )}
            <Collapse
              in={isCollapseOpen(item.id)}
              timeout='auto'
              unmountOnExit
              className='px-3'
            >
              <List component='div' disablePadding>
                {item.children
                 .filter((p) => !p.hide && hasUserAccess(p))
                 .map((child) =>
                  builItem(
                    child.id,
                    child.keyTranslate,
                    child.icon || '',
                    false,
                    child.url
                  )
                )}
              </List>
            </Collapse>
          </Fragment>
        );
      } else
        return builItem(
          item.id,
          item.keyTranslate,
          item.icon || '',
          false,
          item.url
        );
    });
  };

  return <List>{buildMenu()}</List>;
};
export default MenuOptions;
