import React, { Fragment, useContext, useState } from 'react';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import { INavigationItem } from '../../../navigation/types';
import navigationConfig from '../../../navigation';
import { default as MenuM } from '@material-ui/core/Menu';
import {
  Button,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RouteNavigation from '../../../constants/RouteNavigation';
import { Loading } from '../../../components';
import { hasUserAccessMenu } from '../../../utilitys/security';
import UserMenu from '../Common/userMenu';
import { userContext } from '../../../hooks/contexts/userContext';
import { hexToRGB } from '../../../utilitys/colors';
import { KeyCodes } from '../../../constants/KeyCode';
import contextApp from '../../../hooks/contexts/contextApp';

const useStyles = makeStyles((theme) => ({
  border: {
    borderBottom: `5px solid ${theme.palette.warning.main}}`,
  },
  colorSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
}));

interface IAnchorElement {
  id: string;
  element: Element | ((element: Element) => Element) | null | undefined;
}

interface IMenuProps {
  onClickMenu: () => void;
  onClickOpenLogin: () => void;
  onChangeStatusAuth: (token: string) => void;
}

const Menu = ({
  onClickMenu,
  onClickOpenLogin,
  onChangeStatusAuth,
}: IMenuProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const { userAuth } = useContext(userContext);
  const [anchorsEl, setAnchorEl] = useState<IAnchorElement[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ctxApp = useContext(contextApp);

  const onClickButtonMenu = (id: string, url?: string) => (event: any) => {
    const anchorsElClone = [...anchorsEl];
    anchorsElClone.push({ id, element: event.currentTarget });
    setAnchorEl(anchorsElClone);
    if (url === RouteNavigation.Home) history.push(url);
    else if (url) history.push(`/${url}`);
  };

  const getAnchorElement = (id: string) => {
    const elem = [...anchorsEl].find((p) => p.id === id);
    return elem?.element;
  };

  const handleClose = (id: string) => {
    const elements = [...anchorsEl].filter((p) => p.id !== id);
    setAnchorEl(elements);
  };

  const onClickItem = (url: string, isExtUrl: boolean) => {
    if (isExtUrl) {
      setIsLoading(true);
      window.location.replace(url);
    }
    if (url) history.push(`/${url}`);
  };

  const onKeyDownButtonMenu = (event: any) => {
    if (event.keyCode === KeyCodes.Enter) event.currentTarget.click();
  };

  const buildMenu = () => {
    return navigationConfig
      .filter((p) => !p.hide && hasUserAccess(p))
      .map((item) => {
        return (
          <div key={`menuOptionHorizontal${item.id}`} className='h-full'>
            {buildButton(item)}
            {item.children && builItem(item)}
          </div>
        );
      });
  };

  const hasUserAccess = (menu: INavigationItem) => {
    const _hasAccess = hasUserAccessMenu(menu, userAuth);
    if (userAuth && menu.hideOnAuthenticated) return false;
    return _hasAccess;
  };

  const getTextTranslate = (keyTranslate: string) =>
    ctxApp.menuOptions?.find(x => x.code === keyTranslate)?.value || t(`navigation.${keyTranslate}`);

  const buildButton = ({ id, url, icon, keyTranslate }: INavigationItem) => {    
    return (
      <Tooltip title={getTitleToolTiop(keyTranslate)} placement='top'>
        <div
          className='flex p-2 space-x-2 font-thin rounded-lg h-full text-center items-center cursor-pointer transition ease-in-out transform focus:outline-none'
          onClick={onClickButtonMenu(id, url)}
          tabIndex={0}
          onKeyDown={onKeyDownButtonMenu}
        >
          <Icon color='primary'>{icon}</Icon>
          <span className={clsx(classes.textPrimary, 'capitalize text-sm font-body')}>
            {getTextTranslate(keyTranslate)}
          </span>
        </div>
        {/* </Button> */}
      </Tooltip>
    );
  }

  const getTitleToolTiop = (keyTranslate: string) => (
    <Typography variant='body2'>
      {t(`navigation.toolTips.${keyTranslate}`)}{' '}
    </Typography>
  );

  const optionMenu = (item: INavigationItem, id: string, icon?: string) => {
    return (
      <MenuItem
        key={item.id}
        onClick={() => {
          if (item.urlExt) {
            onClickItem(item.urlExt!, true);
          } else {
            onClickItem(item.url!, false);
          }
          handleClose(id);
        }}
      >
        {item.icon && (
          <ListItemIcon
            onClick={() => {
              if (item.urlExt) {
                onClickItem(item.urlExt!, true);
              } else {
                onClickItem(item.url!, false);
              }
              handleClose(id);
            }}
          >
            <Icon>{item.icon}</Icon>
          </ListItemIcon>
        )}
        <ListItemText
          primary={getTextTranslate(item.keyTranslate)}
          onClick={() => {
            if (item.urlExt) {
              onClickItem(item.urlExt!, true);
            } else {
              onClickItem(item.url!, false);
            }
            handleClose(id);
          }}
        />
      </MenuItem>
    );
  };

  const builItem = ({ id, children, icon }: INavigationItem) => (
    <MenuM
      id={`simple_menu_${id}`}
      style={{ position: 'absolute' }}
      getContentAnchorEl={null}
      anchorEl={getAnchorElement(id)}
      keepMounted
      open={Boolean(getAnchorElement(id))}
      onClose={() => handleClose(id)}
      variant='menu'
      
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      className='rounded-t-none'
    >
      {children &&
        children
          .filter((p) => !p.hide && hasUserAccess(p))
          .map((p) => optionMenu(p, id, icon))}
      <div
        className={clsx(classes.colorSecondary, 'w-full h-0.5 rounded-b')}
      ></div>
    </MenuM>
  );

  return (
    <Toolbar
      component='nav'
      variant='dense'
      className={clsx(
        classes.border,
        'shadow-lg border-t-2 border-opacity-50 border-gray-200'
      )}
      style={{
        backgroundColor: `${hexToRGB(theme.palette.primary.main, 0.035)}`,
      }}
    >
      {isMobile ? (
        <div className='w-full flex justify-around'>
          <Button
            aria-controls={`simple_menu_menu`}
            aria-haspopup='true'
            onClick={onClickMenu}
            variant='text'
            className={clsx('font-bold visible sm:invisible')}
            startIcon={<Icon>menu</Icon>}
          >
            {t('navigation.menu')}
          </Button>
          {!userAuth ? (
            <Fragment>
              <IconButton
                aria-label='login'
                color='secondary'
                size='small'
                onClick={onClickOpenLogin}
              >
                <Icon className=''>person</Icon>
              </IconButton>
              <IconButton
                aria-label='register'
                color='secondary'
                size='small'
                onClick={() => onClickItem(RouteNavigation.UserRegister, false)}
              >
                <Icon>app_registration</Icon>
              </IconButton>
            </Fragment>
          ) : (
            <UserMenu
              onLogOut={() => {
                onChangeStatusAuth('');
              }}
            />
          )}
        </div>
      ) : (
        <div className='w-full grid grid-cols-1 lg:grid-cols-5 h-24'>
          <div className='lg:col-span-1'></div>
          <div className='col-span-full lg:col-span-3'>
            <div className='flex justify-between h-full'>{buildMenu()}</div>
          </div>
          <div className='text-right lg:col-span-1 lg:text-right'>
            {!userAuth ? (
              <div className='flex space-x-3 justify-end'>
                <Tooltip
                  title={
                    <Typography variant='body2'>
                      {t(`navigation.toolTips.login`)}
                    </Typography>
                  }
                  placement='top'
                >
                  <IconButton
                    aria-label='login'
                    color='secondary'
                    size='medium'
                    onClick={onClickOpenLogin}
                  >
                    <Icon fontSize='large'>person</Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <Typography variant='body2'>
                      {t(`navigation.toolTips.register`)}
                    </Typography>
                  }
                  placement='top'
                >
                  <IconButton
                    aria-label='register'
                    color='secondary'
                    size='medium'
                    onClick={() =>
                      onClickItem(RouteNavigation.UserRegister, false)
                    }
                  >
                    <Icon fontSize='large'>app_registration</Icon>
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <UserMenu
                showInformation
                onLogOut={() => {
                  onChangeStatusAuth('');
                }}
              />
            )}
          </div>
        </div>
      )}
      <Loading loading={isLoading} />
    </Toolbar>
  );
};

export default Menu;
