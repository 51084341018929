import { getData } from '../_base';
import { API_ECM } from '../constants';
import { FeaturesToggleDto } from '../../dto/FeaturesToggleDto';

const base = `${API_ECM}/v2/FeatureToggle`;
const url = {
    getAll: `${base}/GetAll`
};

export const getAllFeaturesToggle = async (): Promise<FeaturesToggleDto | undefined> => {
    const _url = url.getAll;
    const result = await getData<FeaturesToggleDto>(_url);
    if (result && !result.hasError && result.data ) return result.data as FeaturesToggleDto;
    return undefined;
};