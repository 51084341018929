import { useState, useRef, useEffect } from 'react';
import { ButtonDelete, Required, TooltipHelp } from '.';
import { Button, InputLabel, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

const mimeType = 'audio/webm';

interface AudioRecorderProps {
  file?: Blob;
  label: string | undefined;
  isRequired?: boolean;
  textHelp?: string;
  error?: string;
  onLoadFile?: (files: Blob | undefined) => void;
}

const useStyles = makeStyles((theme) => ({
  text: {
    width: '100%',
    color: theme.palette.text.primary,
  },
}));

const AudioRecorder = ({
  file,
  label,
  textHelp,
  isRequired,
  error,
  onLoadFile,
}: AudioRecorderProps) => {
  const { t } = useTranslation();
  const hasError = !isEmpty(error);
  const classes = useStyles();
  const mediaRecorder = useRef<any>(null);
  const [recordingStatus, setRecordingStatus] = useState<
    'inactive' | 'recording' | 'accepted'
  >('inactive');
  const [audioChunks, setAudioChunks] = useState<any[]>([]);
  const [audio, setAudio] = useState<Blob | undefined>(file);
  const [recordingTime, setRecordingTime] = useState<number>(0);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
  
    if (recordingStatus === 'recording') {
      intervalId = setInterval(() => {
        setRecordingTime(prevTime => prevTime + 1);
      }, 1000);
    }else {
      setRecordingTime(0)
    }
  
    return () => clearInterval(intervalId);
  }, [recordingStatus]);


  const startRecording = async () => {
    const streamData = await allowPermission();
    if (!streamData) return;
    setRecordingStatus('recording');
    const media = new MediaRecorder(streamData, { mimeType: mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();
    let localAudioChunks: any[] = [];
    mediaRecorder.current.ondataavailable = (event: any) => {
      if (typeof event.data === 'undefined') return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };

  const stopRecording = () => {
    setRecordingStatus('inactive');
    if (mediaRecorder && mediaRecorder.current) {
      mediaRecorder.current.stop();
      mediaRecorder.current.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
        setAudio(audioBlob);
        setAudioChunks([]);
      };
    }
  };

  const deleteRecording = () => {
    setRecordingStatus('inactive');
    setAudio(undefined);
    setAudioChunks([]);
    if (onLoadFile) onLoadFile(undefined);
  };

  const acceptRecording = () => {
    setRecordingStatus('accepted');
    if (onLoadFile) onLoadFile(audio);
  };

  const allowPermission = async (): Promise<MediaStream | undefined> => {
    if ('MediaRecorder' in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        return streamData;
      } catch (err: any) {
        alert(err.message);
        return undefined;
      }
    } else {
      alert(t('components.audioRecorder.errorMediaRecorder'));
      return undefined;
    }
  };

  const stopRecordingAndDisableMicrophone = () => {
    stopRecording();
    const tracks = mediaRecorder.current.stream.getAudioTracks();
    tracks.forEach((track: any) => track.stop());
  };

  const TimeCounter = ({ time }: { time: number }) => {
    const minutes = Math.floor(time / 60).toString().padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
  
    return <span className='text-xl text-gray-500'>{`${minutes}:${seconds}`}</span>;
  };

  return (
    <>
      <div className='h-10'>
        <InputLabel className={classes.text}>
          {label} {isRequired && <Required />}
          {textHelp && <TooltipHelp text={textHelp} />}
        </InputLabel>
      </div>
      <div
        className={`flex flex-col w-full items-center justify-center bg-grey-lighter space-y-2`}
      >
        <div>
          {audio ? (
            <div className='flex flex-row'>
              <audio src={URL.createObjectURL(audio)} controls></audio>
            </div>
          ) : null}
        </div>
        <div className='space-x-2'>
          {!audio && recordingStatus === 'inactive' ? (
            <Button
              variant='contained'
              color='secondary'
              onClick={startRecording}
            >
              {t('components.audioRecorder.start')}
            </Button>
          ) : null}

          {recordingStatus === 'inactive' && audio ? (
          <Button variant='contained' color='primary' onClick={acceptRecording}>
            {t('components.audioRecorder.accept')}
          </Button>
          ) : null}  
          {(recordingStatus === 'inactive' && audio) ||
          recordingStatus === 'accepted' ? (
            <ButtonDelete
              showIcon
              messageConfimation={t(
                'components.audioRecorder.confirmationDelete'
              )}
              key='deleteRecording'
              actionAfterConfirmation={deleteRecording}
            />
          ) : null}

          {recordingStatus === 'recording' ? (
            <div>
              <div className='flex flex-col space-y-2'>
                {recordingStatus === 'recording' ? (
                  <span className='font-semibold font-title text-gray-500'>
                    {t('components.audioRecorder.recording')}{' '}
                    <TimeCounter time={recordingTime} />
                  </span>
                ) : null}
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={stopRecordingAndDisableMicrophone}
                >
                  {t('components.audioRecorder.stop')}
                </Button>
              </div>
            </div>
          ) : null}

          {hasError && (
            <span className='font-bold text-red-600 italic ml-5'>{error}</span>
          )}
        </div>
      </div>
    </>
  );
};
export default AudioRecorder;
