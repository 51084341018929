import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import useJwtAuthentication from './hooks/effects/useJwtAuthentication';
import ContextApp from './hooks/contexts/contextApp';
import { useStateWithLocalStorage } from './hooks/state/useStateWithLocalStorage';
import { STORAGE_FEATURES_TOGGLE, STORAGE_THEME } from './constants/App';
import {
  Authorization,
  LoginModal,
  SessionValidator,
  SplashScreen,
} from './containers';
import { STORAGE_TOKEN } from './api/constants';
import SelectorTheme from './themes/SelectorTheme';
import { LayoutPrincipal, LayoutSecondary } from './layout';
import { ContextAppDto } from './dto/ContextAppDto';
import { getContextsAppApi } from './api/contextApp';
import RouteNavigation from './constants/RouteNavigation';
import { defaultContextsApp } from './contextApp';
import { useTranslation } from 'react-i18next';
import { isEqual } from './utilitys/string';
import { userContext } from './hooks/contexts/userContext';
import useUser from './hooks/state/useUser';
import { buildContext } from './utilitys/context';
import { RoutesApp } from './routes';
import { getCurrentLanguageDto } from './utilitys/language';
import { FeaturesToggleDto } from './dto/FeaturesToggleDto';
import { getAllFeaturesToggle } from './api/featureToggle/index';

const App = () => {
  const useContext = useUser();
  const location = useLocation();
  const [token, setToken] = useStateWithLocalStorage<string>(STORAGE_TOKEN);
  const [fontSize, setFontSize] = useState<number>(14);
  const [openLoginModal, setOpenLoginModal] = React.useState(false);
  const { i18n } = useTranslation();
  const [allContextApp, setAllContextApp] = useState<ContextAppDto[]>([]);
  const [isContextLoaded, setIsContextLoaded] = useState<boolean>(false);

  const [contextApp, setContextApp] =
    useState<ContextAppDto>(defaultContextsApp);

  const [themeSelected, setThemeSelected] =
    useStateWithLocalStorage(STORAGE_THEME);

  useJwtAuthentication(token, useContext.setCurrentUser);

  const [,setFeaturesToggle] = useStateWithLocalStorage<FeaturesToggleDto>(STORAGE_FEATURES_TOGGLE);

  useEffect(() => {
    if (i18n.language.includes('-'))
      i18n.changeLanguage(getCurrentLanguageDto()?.code.toLowerCase());
  }, [i18n]);

  useEffect(() => {
    const callApi = async () => {
      const _featuresToggle = await getAllFeaturesToggle();
      if (_featuresToggle) setFeaturesToggle(_featuresToggle);

      const _contextApp = await getContextsAppApi();
      if (_contextApp) setAllContextApp(buildContext(_contextApp));
      setIsContextLoaded(true);
    };
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    const currentContext = allContextApp.find((p) =>
      isEqual(p.language, i18n.language)
    );
    if (currentContext) {
      const iconRef: any = document.getElementById('favicon');
      iconRef.href = currentContext.icoUrl;
      if (currentContext.name) document.title = currentContext.name;
      setContextApp(currentContext);
    }
  }, [i18n.language, allContextApp]);

  const handleOnChangeStatusAuth = (token: string) => {
    setToken(token);
    window.location.replace(window.location.origin);
  };

  const handleOnChangeTheme = (theme: string) => setThemeSelected(theme);

  const handleOnChangeFontSize = (more: boolean) => {
    const newFont = more ? fontSize + 2 : fontSize - 2;
    if (newFont > 28 || newFont < 8) return;
    setFontSize(newFont);
  };

  useEffect(() => {
    if (
      location.pathname.toLocaleLowerCase() ===
      `/${RouteNavigation.FocerLogin.toLocaleLowerCase()}`
    ) {
      setOpenLoginModal(true);
    }
  }, [location.pathname]);

  const createRoutes = (): React.ReactElement[] => {
    const routesFinal: React.ReactElement[] = [];
    RoutesApp.forEach((route) => {
      return routesFinal.push(
        <Route
          key={`key_${route.url}`}
          exact
          path={`/${route.url}`}
          component={route.component}
        />
      );
    });
    return routesFinal;
  };

  const buildBody = () => (
    <Switch>
      <Authorization
        token={token}
        onChangeStatusAuth={handleOnChangeStatusAuth}
      >
        {createRoutes()}
      </Authorization>
    </Switch>
  );

  return (
    <ContextApp.Provider value={contextApp}>
      <userContext.Provider value={useContext}>
        <SessionValidator onChangeStatusAuth={handleOnChangeStatusAuth}>
          <SelectorTheme themeSelected={themeSelected} fontSize={fontSize}>
            {!isContextLoaded && <SplashScreen />}
            {isContextLoaded &&
              (contextApp.layout === 0 ? (
                <LayoutPrincipal
                  onChangeTheme={handleOnChangeTheme}
                  onChangeStatusAuth={handleOnChangeStatusAuth}
                  onChangeFontSize={handleOnChangeFontSize}
                  themeSelected={themeSelected}
                  onOpenLogin={() => setOpenLoginModal(true)}
                >
                  {buildBody()}
                </LayoutPrincipal>
              ) : (
                <LayoutSecondary
                  onChangeTheme={handleOnChangeTheme}
                  onChangeStatusAuth={handleOnChangeStatusAuth}
                  onChangeFontSize={handleOnChangeFontSize}
                  themeSelected={themeSelected}
                  onOpenLogin={() => setOpenLoginModal(true)}
                >
                  {buildBody()}
                </LayoutSecondary>
              ))}
            <LoginModal
              open={openLoginModal}
              onClose={() => setOpenLoginModal(false)}
              onChangeStatusAuth={handleOnChangeStatusAuth}
              disableBackdropClick={true}
            />
          </SelectorTheme>
        </SessionValidator>
      </userContext.Provider>
    </ContextApp.Provider>
  );
};

export default App;
