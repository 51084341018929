import { API_ECM } from '../constants';
import { postFormData } from '../_base';
import { TranscribeSpeechAudioResponse } from './types';
import { blobToFile } from '../../utilitys/file';

const base = `${API_ECM}/v2/TranscribeSpeech`;

const url = {
  transcribeAudio: `${base}/Audio`,
};

export const transcribeSpeech = async (
  blob: Blob,
  fileName: string
): Promise<TranscribeSpeechAudioResponse | undefined> => {
  const _url = `${url.transcribeAudio}`;
  const formData = new FormData();
  const file = blobToFile(blob, fileName);
  formData.append('audio', file, fileName);
  const result = await postFormData<TranscribeSpeechAudioResponse>(_url, formData);
  if (result && !result.hasError && result.data) return result.data;
  else return undefined;
};
