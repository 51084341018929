
export function getBuffer(fileData: File) {
  return function(resolve: any) {
    const reader = new FileReader();
      reader.readAsArrayBuffer(fileData);
      reader.onload = function() {
        const arrayBuffer:any = reader.result
        const bytes = new Uint8Array(arrayBuffer);
        resolve(bytes);
      }
  }
}

export function readFileAsync(file: File) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  })
}

export function blobToFile(blob: Blob, fileName: string): File {
  const file = new File([blob], fileName, { type: blob.type });
  return file;
}