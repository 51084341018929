import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { getPolicy } from '../../api/pqrs';
import { EnumTypePQRS } from '../../constants/PqrsType';
import { PqrsPolicyDto } from '../../dto/pqrs';
import {
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Alert } from '../../components';
import { IAlertTypes } from '../../components/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface IPolicyDataProps {
  open: boolean,
  typePqrs: EnumTypePQRS;
  onChange: (accepted: boolean) => void;
  onClose: () => void
}

const PolicyData = ({ open, typePqrs, onChange, onClose }: IPolicyDataProps) => {
  const { t } = useTranslation();
  const [openAlert, setOpenAlert] = useState(false);
  const [policy, setPolicy] = React.useState<PqrsPolicyDto | undefined>();
  const [accepted, setAccepted] = React.useState<boolean>(false);

  const classes = useStyles();
  useEffect(() => {
    const fetch = async () => {
      const policy = await getPolicy(typePqrs);
      setPolicy(policy);
    };
    fetch();
  }, [typePqrs]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccepted((event.target as HTMLInputElement).checked);
  };

  const onClickContinue = () => {
    if (!accepted) {
      setOpenAlert(true);
      return;
    }
    onChange(accepted);
  };

  return (
    <div>
      <Dialog aria-labelledby='customized-dialog-title' open={open}>
        <DialogTitle className={classes.root}>
          <Typography variant='h5'>
            {t('components.policyData.title')}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom className='text-justify'>
            {policy?.policy}
          </Typography>
          <br />
          <a
            href={policy?.policyUrl}
            className='italic text-blue-500 underline'
          >
            {policy?.policyUrl}
          </a>
          <div className='flex justify-center'>
            <FormControlLabel
                control={<Checkbox color='primary' checked={accepted} onChange={handleChange} style={{color: '#339966'}} />}
                label={t('components.policyData.optionYes')}
                labelPlacement='end'
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color='primary'
            variant='contained'
            onClick={onClickContinue}
          >
            {t('components.policyData.buttonContinue')}
          </Button>
          <Button onClick={() => {setAccepted(false); onClose();}} color='inherit' variant='outlined'>
            {t('components.policyData.buttonCancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <Alert
        open={openAlert}
        type={IAlertTypes.warning}
        onClickClose={() => setOpenAlert(false)}
      >
        {t('components.policyData.messageRequired')}
      </Alert>
    </div>
  );
};

export default PolicyData;