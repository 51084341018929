import { PostPrqsDto, IPostPrqsMetaDataDto } from '../../dto/pqrs';
import {
  DocumentMetaDataState,
  TypeControl,
} from '../../containers/DocumentMetaData/types';
import { EnumTypePQRS } from '../../constants/PqrsType';

export const mapStateToDto = (
  typePqrs: EnumTypePQRS,
  metaData: DocumentMetaDataState,
  policyAccepted: boolean
): PostPrqsDto => {
  const dto: PostPrqsDto = {
    typePqrs,
    policyAccepted,
    metaData: [],
  };

  Object.keys(metaData).forEach((key) => {
    if (metaData[`${key}`].type !== TypeControl.file) {
      dto.metaData!.push({
        id: +key,
        value: getValue(metaData[`${key}`].value),
        text: metaData[`${key}`].text,
        typeElement: getTypeElement(metaData[`${key}`].type),
        initial: metaData[`${key}`].initial,
        dicSec: metaData[`${key}`].dicSec
      } as IPostPrqsMetaDataDto);
    }
  });
  return dto;
};

export const mapStateToDtoFiles = (metaData: DocumentMetaDataState): File[] => {
  let Files: File[] = [];
  const keys = Object.keys(metaData);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    if (metaData[`${key}`].type === TypeControl.file) {
      const files = metaData[`${key}`].value as File[];
      Files = Files.concat(files);
    }

    if (
      metaData[`${key}`].type === TypeControl.audio &&
      isSpeechAudioType(metaData[`${key}`].value)
    ) {
      const { audio } = toSpeechAudioType(metaData[`${key}`].value)!;
      const file = new File([audio], `${metaData[`${key}`].label}.mp3`, {
        type: audio.type,
      });
      Files.push(file);
    }
  }
  return Files;
};

export type SpeechAudioType = {
  speech: string;
  audio: Blob;
};

const getValue = (value: any) => {
  if (Array.isArray(value)) {
    return value.join(';');
  }

  if (isSpeechAudioType(value)) {
    return toSpeechAudioType(value)!.speech;
  }

  return value;
};

const getTypeElement = (type: TypeControl) => {
  if(type === TypeControl.audio) return TypeControl.text
  return type;
}

const isSpeechAudioType = (value: any): value is SpeechAudioType =>
  typeof value === 'object' &&
  value !== null &&
  'speech' in value &&
  'audio' in value &&
  typeof value.speech === 'string' &&
  value.audio instanceof Blob;

const toSpeechAudioType = (value: any): SpeechAudioType | undefined => {
  if (
    typeof value === 'object' &&
    value !== null &&
    'speech' in value &&
    'audio' in value
  ) {
    return {
      speech: value.speech,
      audio:
        value.audio instanceof Blob ? value.audio : new Blob([value.audio]),
    } as SpeechAudioType;
  } else {
    return undefined;
  }
};
