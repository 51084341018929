import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { sortBy } from 'lodash';
import { MetaDataConfigurationDocumentaryTypeDto } from '../../dto/MetadataDto';
import DateTextBoxMemo from '../DocumentMetaData/dateTextBoxMemo';
import TextFieldMemo from '../DocumentMetaData//textFieldMemo';
import AutocompleteCustomTable from '../DocumentMetaData//autocompleteCustomTableMemo';
import AutocompleteMultipleCustomTableMemo from '../DocumentMetaData//autocompleteMultipleCustomTableMemo';
import {
  DocumentMetaDataState,
  TypeControl,
  TypeControlData,
} from '../DocumentMetaData/types';
import { convertToDateISOString } from '../../utilitys/date';

interface IDocumentMetaDataValuesProps {
  metaData: MetaDataConfigurationDocumentaryTypeDto[];
}

const DocumentMetaDataValues = ({ metaData }: IDocumentMetaDataValuesProps) => {
  const [state, setState] = useState<DocumentMetaDataState>({});
  const [metaDataLocal, setMetaDataLocal] = useState<
    MetaDataConfigurationDocumentaryTypeDto[]
  >([]);

  const buildInitialState = (
    metadataInitial: MetaDataConfigurationDocumentaryTypeDto[]
  ) => {
    let result = {};
    metadataInitial.forEach((p) => {
      if (p.defaultValue) {
        result = {
          ...result,
          [`${p.id}`]: {
            value: getValue(p),
          },
        };
      }
    });

    return result;
  };

  const getValue = (control: MetaDataConfigurationDocumentaryTypeDto) => {
    if (control.dataType === TypeControlData.date) {
      if(!control.defaultValue) return null;
      return convertToDateISOString(control.defaultValue);
    }
    return control.defaultValue;
  };

  useEffect(() => {
    setState(buildInitialState(metaData));
    setMetaDataLocal(
      sortBy(metaData, (p) => p.order).map((p) => ({ ...p, disabled: true }))
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaData]);
    
  const isVisible = useCallback(
    (control: MetaDataConfigurationDocumentaryTypeDto) => {
      if (control.isVisible || control.defaultValue) return true;
      return false;
    },
    []
  );

  const buildDateTextBox = useCallback(
    (control: MetaDataConfigurationDocumentaryTypeDto, index: number) => (
      <div
        key={`DateTextBoxMemo_${control.id}`}
        className={isVisible(control) ? 'visible' : 'hidden'}
      >
        <DateTextBoxMemo
          metaData={control}
          value={state[control.id] ? state[control.id].value : undefined}
        />
      </div>
    ),
    [state, isVisible]
  );

  const buildTextBox = useCallback(
    (control: MetaDataConfigurationDocumentaryTypeDto, index: number) => (
      <div
        key={`TextFieldMemo_${control.id}`}
        className={isVisible(control) ? 'visible' : 'hidden'}
      >
        <TextFieldMemo
          metaData={control}
          value={
            state[control.id] ? state[control.id].value.toString() : undefined
          }
        />
      </div>
    ),
    [state, isVisible]
  );

  const buildAutocomplete = useCallback(
    (control: MetaDataConfigurationDocumentaryTypeDto, index: number) => {
      return (
        <div
          key={`AutocompleteCustomTableMemo_${control.id}`}
          className={isVisible(control) ? 'visible' : 'hidden'}
        >
          <AutocompleteCustomTable
            onChangeValue={() => {}}
            isParentdependent={false}
            isdependent={false}
            metaData={control}
            value={
              state[control.id] ? state[control.id].value.toString() : undefined
            }
          />
        </div>
      );
    },
    [state, isVisible]
  );

  const buildMultiSelect = useCallback(
    (control: MetaDataConfigurationDocumentaryTypeDto, index: number) => (
      <div
        key={`AutocompleteCustomTableMemo_${control.id}`}
        className={isVisible(control) ? 'visible' : 'hidden'}
      >
        <AutocompleteMultipleCustomTableMemo
          isOutlined
          metaData={control}
          value={
            state[control.id]
              ? (state[control.id].value as string[])
              : undefined
          }
        />
      </div>
    ),
    [state, isVisible]
  );

  const buildControls = useCallback(
    () =>
      sortBy(metaDataLocal, (p) => p.order).map((control, index) => {
        switch (control.controlType) {
          case TypeControl.text:
            if (control.dataType === TypeControlData.date) {
              return buildDateTextBox(control, index);
            }
            return buildTextBox(control, index);
          case TypeControl.file:
            return undefined;
          case TypeControl.multipleSelect:
            return buildMultiSelect(control, index);
          case TypeControl.list:
            return buildAutocomplete(control, index);
          default:
            return buildTextBox(control, index);
        }
      }),
    [
      metaDataLocal,
      buildTextBox,
      buildAutocomplete,
      buildDateTextBox,
      buildMultiSelect,
    ]
  );

  return <Fragment>{buildControls()}</Fragment>;
};
export default DocumentMetaDataValues;
