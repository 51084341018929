import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { getUserTableInformationApi } from '../../api/AdmInquiries';
import { getMetadataAdminByMetadataId } from '../../api/backOffice';
import { BpmProcessInformationDto } from '../../api/backOffice/dto';
import { Alert, AutocompleteStyled } from '../../components';
import { InformationCustomTableDto } from '../../dto/admin/types';
import { MetaDataConfigurationDocumentaryTypeDto } from '../../dto/MetadataDto';
import {
  HandleDocumentMetaDataChild,
  ResultDocumentMetaDataChild,
  TypeControl,
} from './types';
import { IAlertTypes } from '../../components/Alert';

interface IAutocompleteCustomTableProps {
  metaData: MetaDataConfigurationDocumentaryTypeDto;
  documentType?: number;
  isdependent: boolean;
  isParentdependent: boolean;
  fieldDepedent?: string;
  valueDependent?: string;
  error?: string;
  value?: string;
  processBpmInformation?: BpmProcessInformationDto;
  onChangeValue: (initial: string, valueSelected: string | undefined) => void;
}

const defaultValue: InformationCustomTableDto = {
  id: '',
  value: '',
};

const AutocompleteCustomTableMemo = memo(
  forwardRef(
    (
      {
        metaData,
        documentType,
        value,
        error,
        isdependent,
        fieldDepedent,
        valueDependent,
        isParentdependent,
        onChangeValue,
        processBpmInformation,
      }: IAutocompleteCustomTableProps,
      ref: React.Ref<HandleDocumentMetaDataChild | undefined>
    ) => {
      const [options, setOptions] = useState<InformationCustomTableDto[]>([]);
      const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
      const [messageAlert, setMessageAlert] = useState<string>();
      const [selectedValue, setSelectedValue] = useState<string | undefined>(
        value
      );

      const handlerOnChangeAutocomplete = async (e: any, value: any | null) => {
        const info = value as InformationCustomTableDto;
        if (info) setSelectedValue(info.id);
        else setSelectedValue(undefined);
        if (info) onChangeValue(metaData.initial, info.id);
        else onChangeValue(metaData.initial, undefined);

        if(metaData.attributeFormId && processBpmInformation && info){
          const metadataAdminBpm = await getMetadataAdminByMetadataId(processBpmInformation.formId, metaData.attributeFormId, info.id);
          if(metadataAdminBpm?.messageAlert){
            setMessageAlert(metadataAdminBpm.messageAlert);
            setIsAlertOpen(true)
          }
        }

      };

      useEffect(() => {
        const fetch = async () => {
          const { listeName, listeTexte, listeValue, dicSec } = metaData;
          let result: InformationCustomTableDto[] = [];
          if (!isdependent) {
            result = await getUserTableInformationApi(
              listeName,
              listeValue,
              listeTexte,
              documentType,
              dicSec
            );
          } else if (isdependent && fieldDepedent && valueDependent) {
            result = await getUserTableInformationApi(
              listeName,
              listeValue,
              listeTexte,
              documentType,
              dicSec,
              isdependent ? fieldDepedent : undefined,
              isdependent ? valueDependent : undefined
            );
          }
          setOptions(result);
        };
        fetch();
      }, [fieldDepedent, isdependent, metaData, valueDependent, documentType]);

      const getOptionSelected = (): InformationCustomTableDto => {
        if (selectedValue) {
          const selected = options.find((p) => p.id === selectedValue);
          return selected ? selected : defaultValue;
        }
        return defaultValue;
      };

      useImperativeHandle(ref, () => ({
        getData() {
          const option = getOptionSelected();
          return {
            id: metaData.id,
            value: option.id,
            text: option.value,
            typeElement: TypeControl.list,
            label: metaData.label,
            initial: metaData.initial,
            dicSec: metaData.dicSec,
            attributeFormId: metaData.attributeFormId,
          } as ResultDocumentMetaDataChild;
        },
      }));

      return (
        <>
          <AutocompleteStyled
            disabled={metaData.disabled || !metaData.isEditable}
            textHelp={metaData.toolTip}
            isOutlined
            error={error}
            isRequired={metaData.isRequired}
            options={options}
            label={metaData.label}
            value={getOptionSelected()}
            onChange={handlerOnChangeAutocomplete}
            getOptionLabel={(option: InformationCustomTableDto) => option.value}
            renderInput={() => <></>}
          />
          <Alert open={isAlertOpen} type={IAlertTypes.info} onClickClose={() => setIsAlertOpen(false) }>
            {messageAlert}
          </Alert>
        </>
      );
    }
  )
);

export default AutocompleteCustomTableMemo;
