import React, {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { getUserTableInformationApi } from '../../api/AdmInquiries';
import { getMetadataAdminByMetadataId } from '../../api/backOffice';
import { BpmProcessInformationDto } from '../../api/backOffice/dto';
import { Alert, AutocompleteStyled } from '../../components';
import { IAlertTypes } from '../../components/Alert';
import { InformationCustomTableDto } from '../../dto/admin/types';
import { MetaDataConfigurationDocumentaryTypeDto } from '../../dto/MetadataDto';
import {
  HandleDocumentMetaDataChild,
  ResultDocumentMetaDataChild,
  TypeControl,
} from './types';

interface IAutocompleteCustomTableProps {
  metaData: MetaDataConfigurationDocumentaryTypeDto;
  documentType?: number;
  error?: string;
  value?: string[];
  isOutlined?: boolean;
  processBpmInformation?: BpmProcessInformationDto;
  isMultiSelect?: boolean;
}

const AutocompleteMultipleCustomTableMemo = memo(
  forwardRef(
    (
      {
        metaData,
        documentType,
        value,
        error,
        isOutlined,
        processBpmInformation,
        isMultiSelect
      }: IAutocompleteCustomTableProps,
      ref: React.Ref<HandleDocumentMetaDataChild | undefined>
    ) => {
      const [options, setOptions] = useState<InformationCustomTableDto[]>([]);
      const [selectedValue, setSelectedValue] = useState<string[] | undefined>(
        value
      );
      const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
      const [messageAlert, setMessageAlert] = useState<string>();

      const handlerOnChangeAutocomplete = async (e: any, value: any | null) => {
        const values = value as InformationCustomTableDto[];
        if (values) {
          const selectedValueClone = [...(selectedValue || [])];
          setSelectedValue(values.map((p) => p.id));

          if (metaData.attributeFormId && processBpmInformation) {
            const [newId] = values
              .map((p) => p.id)
              .filter((x) => !selectedValueClone.includes(x));
            if (newId) {
              const metadataAdminBpm = await getMetadataAdminByMetadataId(
                processBpmInformation.formId,
                metaData.attributeFormId,
                newId
              );
              if (metadataAdminBpm?.messageAlert) {
                setMessageAlert(metadataAdminBpm.messageAlert);
                setIsAlertOpen(true);
              }
            }
          }
        } else setSelectedValue(undefined);
      };

      useEffect(() => {
        const fetch = async () => {
          const { listeName, listeTexte, listeValue, dicSec } = metaData;
          let result: InformationCustomTableDto[] = [];
          result = await getUserTableInformationApi(
            listeName,
            listeValue,
            listeTexte,
              documentType,
              dicSec
          );
          setOptions(result);
        };
        fetch();
      }, [metaData, documentType]);

      const getOptionSelected = (): InformationCustomTableDto[] => {
        if (selectedValue) {
          return options.filter((p) => selectedValue.includes(p.id));
        }
        return [];
      };

      useImperativeHandle(ref, () => ({
        getData() {
          const option = getOptionSelected();
          return {
            id: metaData.id,
            value: option.map((p) => p.id),
            text: option.map((p) => p.value).join(';'),
            typeElement: TypeControl.list,
            label: metaData.label,
            initial: metaData.initial,
            attributeFormId: metaData.attributeFormId,
            dicSec: metaData.dicSec
          } as ResultDocumentMetaDataChild;
        },
      }));

      return (
        <>
          <AutocompleteStyled
            disabled={metaData.disabled || !metaData.isEditable}
            multiple={true}
            textHelp={metaData.toolTip}
            isOutlined={isOutlined}
            error={error}
            disableCloseOnSelect
            isRequired={metaData.isRequired}
            options={options}
            label={metaData.label}
            value={getOptionSelected()}
            onChange={handlerOnChangeAutocomplete}
            getOptionLabel={(option: InformationCustomTableDto) => option.value}
            renderInput={() => <></>}
          />
          <Alert
            open={isAlertOpen}
            type={IAlertTypes.info}
            onClickClose={() => setIsAlertOpen(false)}
          >
            {messageAlert}
          </Alert>
        </>
      );
    }
  )
);

export default AutocompleteMultipleCustomTableMemo;
