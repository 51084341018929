import Button from '@material-ui/core/Button/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import IconButton from '@material-ui/core/IconButton/IconButton';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { red } from '@material-ui/core/colors';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from '@material-ui/core/styles/withStyles';

interface IButtonDeleteProps {
  actionAfterConfirmation: () => void;
  messageConfimation: string;
  onlyIcon?: boolean;
  showIcon?: boolean;
}

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: '#fff',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);


const ButtonDelete = ({ onlyIcon, showIcon, messageConfimation, actionAfterConfirmation }: IButtonDeleteProps) => {
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpenDialogConfirmation(true);
  };
  const handleClose = () => {
    setOpenDialogConfirmation(false);
  };
  const handleAgree = () => {
    actionAfterConfirmation();
    setOpenDialogConfirmation(false);
  };

  return (
    <Fragment>
      {onlyIcon && (
        <IconButton aria-label='delete' color='secondary' style={{ color: red[500] }} onClick={handleClickOpen}>
          <DeleteIcon fontSize='small' />
        </IconButton>
      )}
      {!onlyIcon && (
        <ColorButton variant="contained" onClick={handleClickOpen} startIcon={showIcon && <DeleteIcon fontSize='small'/>}>
          {t('components.buttonDelete.title')}
        </ColorButton>
      )}

      <Dialog
        open={openDialogConfirmation}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description' color='textPrimary'>{messageConfimation}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            {t('components.buttonDelete.buttonNo')}
          </Button>
          <Button onClick={handleAgree} color='primary' autoFocus>
            {t('components.buttonDelete.buttonYes')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ButtonDelete;
